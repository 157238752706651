<template>
  <nuxt></nuxt>
</template>

<script>
export default {
  name: 'blankLayout'
}
</script>

<style lang="stylus">
</style>
